<template>
  <ion-page>
    <ion-content :fullscreen="true" :scroll-events="true" class="main-content">

      <div class="container">
        <Header activePage="Servicios"></Header>

        <section id="slider" class="no-container mb-xl">
          <AnimatedComponent animation-enter="animate__fadeIn">
            <swiper class="swiper-imagenes" :modules="modules" :navigation="false" :pagination="false" :slidesPerView="1" :spaceBetween="24" :loop="false" :grabCursor="true">
              <swiper-slide>
                <div class="swiper-imagen">
                  <video controls autoplay>
                    <source src="assets/video/21.mp4" type="video/mp4">
                    Your browser does not support the video tag.
                  </video>
                </div>
              </swiper-slide>
            </swiper>
          </AnimatedComponent>
        </section>

        <section id="sobre" class="mb-xxl">
          <ion-grid>
            <ion-row>
              
              <ion-col size="12" size-lg="6">
                <div class="about-text">
                  <AnimatedComponent animation-enter="animate__fadeIn">
                    <h2 class="pb-sm">Los Reyes de La Guía de Chiclana 2023</h2>
                    <p class="about-paragraph pb-xxs">
                      Con esta curiosa iniciativa La Guía de Chiclana, vuelve a organizar por quinto año consecutivo una campaña para las empresas y profesionales de Chiclana, en las fiestas navideñas.
                    </p>

                    <p class="about-paragraph pb-xxs">
                      Ya se pueden encontrar en las empresas la carta para dirigirla a los Reyes de La Guía de Chiclana, para que el día 5 de enero 2023 puedan ser elegidas , las tres cartas ganadoras, de entre todas las cartas recogidas en el Buzón que el Cartero de La Guía de Chiclana.
                    </p>

                    <p class="about-paragraph pb-xxs">
                      Este año nos encontraremos los días 27 y 30 de Diciembre en la Plaza de la Bodegas de 10:30 a 13:30. Además, estaremos el día 4 de Enero en el Centro Comercial Huerta del Rosario de 10:30 a 13:30 y de 17:30 a 20:30.
                    </p>

                    <p class="about-paragraph pb-xxs">
                      Hay que reseñar que se esperan muchísimos regalos para cada uno de los elegidos con su carta por Sus Majestades los Reyes de La Guía de Chiclana, y que dichos regalos se encuentran en esta página.
                    </p>

                    <p class="about-paragraph pb-xxs">
                      Las cartas se entregarán en los comercios y todas las cartas recogidas en estos se entregarán al Cartero de La Guía de Chiclana y el día 5 de enero Los Reyes de La Guía de Chiclana elijan las tres cartas en un directo a través del Facebook Chiclana Guía de Empresas y este año también se podrá asistir de manera presencial en el Hogar de mayores San Antonio, donde contaremos además con actuaciones de artistas en directo y sorteos entre el público.
                    </p>
                    
                    <p class="about-paragraph pb-xxs">
                      Con esta campaña original y diferente de La Guía de Chiclana se ha conseguido devolver la ilusión a los mayores de 18 años.
                    </p>

                    <p class="about-paragraph pb-xxs">
                      Para hacer posible la campaña colaboran las empresas Evolbe Systems, Okinami, BotyMania, Hogar de San Antonio y Chiclana Guía de Empresas.
                    </p>

                  </AnimatedComponent>
                </div>
              </ion-col>
              <ion-col size-lg="1"></ion-col>
              <ion-col size="12" size-lg="5">
                <AnimatedComponent animation-enter="animate__fadeIn">
                  <img class="about-image" src="https://firebasestorage.googleapis.com/v0/b/mini-web-8bbb0.appspot.com/o/losreyesdelaguiadechiclana.com%2Fthumbnails%2Fcartel_2048x2048.webp?alt=media" alt="Los Reyes de La Guía de Chiclana"/>
                </AnimatedComponent>
              </ion-col>
            </ion-row>
          </ion-grid>
        </section>

        <section id="caracteristicas" class="mb-xxl">
          <ion-grid>
            <ion-row class="caracteristicas-container">
              <ion-col size="12" size-sm="6" size-lg="4">
                <AnimatedComponent animation-enter="animate__fadeIn">
                  <ion-card class="feature">
                    <ion-card-content>
                      <img class="icon" src="assets/img/melchor.png" alt="Los Reyes de La Guía de Chiclana"/>
                      <h3 class="title">Melchor</h3>
                      <h5>Regalos</h5>
                      <p class="description">Próximamente...</p>
                    </ion-card-content>
                  </ion-card>
                </AnimatedComponent>
              </ion-col>
              <ion-col size="12" size-sm="6" size-lg="4">
                <AnimatedComponent animation-enter="animate__fadeIn">
                  <ion-card class="feature">
                    <ion-card-content>
                      <img class="icon" src="assets/img/gaspar.png" alt="Los Reyes de La Guía de Chiclana"/>
                      <h3 class="title">Gaspar</h3>
                      <h5>Regalos</h5>
                      <p class="description">Próximamente...</p>
                    </ion-card-content>
                  </ion-card>
                </AnimatedComponent>
              </ion-col>
              <ion-col size="12" size-sm="6" size-lg="4">
                <AnimatedComponent animation-enter="animate__fadeIn">
                  <ion-card class="feature">
                    <ion-card-content>
                      <img class="icon" src="assets/img/baltasar.png" alt="Los Reyes de La Guía de Chiclana"/>
                      <h3 class="title">Baltasar</h3>
                      <h5>Regalos</h5>
                      <p class="description">Próximamente...</p>
                    </ion-card-content>
                  </ion-card>
                </AnimatedComponent>
              </ion-col>
            </ion-row>
          </ion-grid>
        </section>

        <section id="galeria" class="mb-xxl">
          <ion-grid>
            <ion-row class="mb-md">
              <ion-col size="12">
                <h2>Un año más, vuelve la ilusión para los mayores de 18 años</h2>
                <h4 class="color-medium normal pt-xs">Ganadores de años anteriores</h4>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="12">
                <div class="mansory-gallery">
                  <div class="mansory-img" v-for="(image, i) in gallery" :key="i" >
                    <AnimatedComponent animation-enter="animate__fadeIn">
                      <img :src="image.src" alt="Los Reyes de La Guía de Chiclana" />
                    </AnimatedComponent>
                  </div>
                </div>
             </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="12">
                <ion-button shape="round" style="margin:0 auto; margin-top: 48px; display: table; --background: #E1306C"  target="_blank" href="https://www.instagram.com/laguiadechiclana/">
                  <ion-icon slot="start" src="/assets/img/uicons/fi-brands-instagram.svg" style="margin-right: 12px; color: #fff;"></ion-icon>
                  <ion-label style="color: #fff;">Síguenos en Instagram</ion-label>
                </ion-button>
                <ion-button shape="round" style="margin:0 auto; margin-top: 16px; display: table; --background: #4267B2" target="_blank" href="https://www.facebook.com/chiclanaguiadempresas">
                  <ion-icon slot="start" src="/assets/img/uicons/fi-brands-facebook.svg" style="margin-right: 12px; color: #fff;"></ion-icon>
                  <ion-label style="color: #fff;">Síguenos en Facebook</ion-label>
                </ion-button>
              </ion-col>
            </ion-row>
          </ion-grid>
        </section>

        <section id="preguntas" class="mb-xxl">
          <ion-grid>
            <ion-row>
              <ion-col size="12">
                <ion-accordion-group class="faqs">
                  <ion-accordion value="zero">
                    <ion-item slot="header" color="transparent" lines="none">
                      <ion-label text-wrap><h5>Proceso de Participación y Sorteo</h5></ion-label>
                    </ion-item>
                    <div class="pb-xs" slot="content">
                      <p class="pb-xxs">Desde el 1 de diciembre 2022 hasta el 4 de enero 2023 podrán empezar a recoger las cartas en las empresas y profesionales participantes en la campaña.</p>
                      <p class="pb-xxs">Para conseguir la Carta tienen que ser cliente de la empresa o profesional donde quieras conseguirla y que participen en la campaña.</p>
                      <p class="pb-xxs">Tendrás que rellenar la Carta debidamente con el nombre, teléfono y sello de la empresa o profesional participante.</p>
                      <p class="pb-xxs">Este año nos encontraremos los días 27 y 30 de Diciembre en la Plaza de la Bodegas de 10:30 a 13:30. Además, estaremos el día 4 de Enero en el Centro Comercial Huerta del Rosario de 10:30 a 13:30 y de 17:30 a 20:30.</p>
                      <p class="pb-xxs">El día 5 de enero 2023 entre las 11:00 y las 13:00 se celebrará la Gala en el Hogar de San Antonio y será restrasmitida en directo por el Facebook Chiclana Guía de Empresas, de entre todas las Cartas recogidas por el Cartero de La Guía de Chiclana serán elegidas 3 Cartas, y tres Cartas suplentes por cada Rey que se enumerarán del 1 al 3 correspondientes a: Sus Majestades el Rey Melchor, Rey Gaspar y Rey Baltasar.</p>
                      <p class="pb-xxs">Los ganadores que serán avisados por teléfono tendrán una hora para presentarse en el salón de actos para recoger su premio, si no apareciera se llamaría al primer suplente y así, sucesivamente hasta que todos los regalos estén entregados.</p>
                      <p class="pb-xxs">Cualquier duda o situación será resuelta por la Junta Directiva de la Asociación.</p>
                      <p class="pb-xxs">Todas las listas de regalos estarán publicadas en el Facebook Chiclana Guía de Empresas, nuestro Instagram y en la página web losreyesdelaguiadechiclana.com.</p>
                    </div>
                  </ion-accordion> 
                  <ion-accordion value="one">
                    <ion-item slot="header" color="transparent" lines="none">
                      <ion-label text-wrap><h5>Condiciones</h5></ion-label>
                    </ion-item>
                    <div class="pb-xs" slot="content">
                      <p class="pb-xxs">La notificación de la llamada a los seleccionados será en directo por teléfono y se podrá ver en el Facebook <a href="https://www.facebook.com/chiclanaguiadempresas" target="_blank">Chiclana Guía de Empresas</a> e Instagram <a href="https://www.instagram.com/laguiadechiclana/" target="_blank">@laguiadechiclana</a>.</p>
                      <p class="pb-xxs">Los seleccionados autorizan y ceden la cesión de derechos de imagen y/o de propiedad intelectual.</p>
                      <p class="pb-xxs">La organización se reserva el derecho de prorroga o suspensión, despenalización y penalización</p>
                      <p class="pb-xxs">Los participantes aceptan las bases y las decisiones tomadas por la Junta Directiva de la Asociación.</p>
                      <p class="pb-xxs">Para participar con la Carta hay que ser mayor de 18 años.</p>
                      <p class="pb-xxs">Quien no reúna alguna de las bases quedará eliminado.</p>
                      <p class="pb-xxs">El día 5 de enero de 2023 Los Reyes de La Guía de Chiclana elegirán de entre todas las Cartas recogidas un total de cuatro Cartas cada uno, y serán enumeradas con Melchor 1 2 3 4, Gaspar 1 2 3 4 y Baltasar 1 2 3 4, a continuación los miembros del jurado pondrán los nombres y teléfonos de todas las Cartas en el Acta para comprobar que ninguna carta tiene el mismo nombre o teléfono, si se eligieran más de una Carta con el mismo nombre o teléfono serian eliminadas todas y se elegirían otras Cartas para sustituir las eliminadas.</p>
                      <p class="pb-xxs">Los ganadores que serán avisados por teléfono tendrán una hora para presentarse en el salón de actos para recoger su premio, si no apareciera se llamaría al primer suplente y así, sucesivamente hasta que todos los regalos estén entregados.</p>
                      <p class="pb-xxs">La organización tiene el email <a href="mailto:info@chiclanaguiadempresas.com ">info@chiclanaguiadempresas.com</a> y teléfono <a href="tel:645564859">645 56 48 59</a> a través de WhatsApp para contacto o reclamación.</p>
                    </div>
                  </ion-accordion>          
                </ion-accordion-group>
              </ion-col>
            </ion-row>
          </ion-grid>
        </section>

        <Footer></Footer>
      </div>
            
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonButton, IonLabel, IonFab, IonFabButton, IonIcon, IonCard, IonCardContent, menuController, IonGrid, IonRow, IonCol, IonRippleEffect, IonItem, IonAccordionGroup, IonAccordion, IonSegment, IonSegmentButton, IonSelect, IonSelectOption, modalController,  } from '@ionic/vue';
import { defineComponent } from 'vue';

import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import AnimatedComponent from '@/components/others/AnimatedComponent.vue';
import StarRating from 'vue-star-rating'

import { Autoplay, EffectCreative, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';

import 'swiper/css';
import "swiper/css/effect-creative";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";

import Details from './Details.vue';
import { mapActions, mapState } from 'vuex';


export default defineComponent({
  name: 'HomePage',
  components: { IonContent, IonPage, Header, Footer, Swiper, SwiperSlide, AnimatedComponent, IonButton, IonLabel, IonIcon, IonCard, IonCardContent, IonGrid, IonRow, IonCol, IonItem, IonAccordionGroup, IonAccordion },
    data() {
    return {
      gallery: [
        {
          src: 'https://firebasestorage.googleapis.com/v0/b/mini-web-8bbb0.appspot.com/o/losreyesdelaguiadechiclana.com%2Fthumbnails%2Fg1_2048x2048.webp?alt=media',
          type: 'image'
        },
        {
          src: 'https://firebasestorage.googleapis.com/v0/b/mini-web-8bbb0.appspot.com/o/losreyesdelaguiadechiclana.com%2Fthumbnails%2Fg2_2048x2048.webp?alt=media',
          type: 'image'
        },
        {
          src: 'https://firebasestorage.googleapis.com/v0/b/mini-web-8bbb0.appspot.com/o/losreyesdelaguiadechiclana.com%2Fthumbnails%2Fg3_2048x2048.webp?alt=media',
          type: 'image'
        },
        {
          src: 'https://firebasestorage.googleapis.com/v0/b/mini-web-8bbb0.appspot.com/o/losreyesdelaguiadechiclana.com%2Fthumbnails%2Fg4_2048x2048.webp?alt=media',
          type: 'image'
        },
        {
          src: 'https://firebasestorage.googleapis.com/v0/b/mini-web-8bbb0.appspot.com/o/losreyesdelaguiadechiclana.com%2Fthumbnails%2Fg5_2048x2048.webp?alt=media',
          type: 'image'
        },
        {
          src: 'https://firebasestorage.googleapis.com/v0/b/mini-web-8bbb0.appspot.com/o/losreyesdelaguiadechiclana.com%2Fthumbnails%2Fg6_2048x2048.webp?alt=media',
          type: 'image'
        },
        {
          src: 'https://firebasestorage.googleapis.com/v0/b/mini-web-8bbb0.appspot.com/o/losreyesdelaguiadechiclana.com%2Fthumbnails%2Fg7_2048x2048.webp?alt=media',
          type: 'image'
        },
        {
          src: 'https://firebasestorage.googleapis.com/v0/b/mini-web-8bbb0.appspot.com/o/losreyesdelaguiadechiclana.com%2Fthumbnails%2Fg8_2048x2048.webp?alt=media',
          type: 'image'
        },
        {
          src: 'https://firebasestorage.googleapis.com/v0/b/mini-web-8bbb0.appspot.com/o/losreyesdelaguiadechiclana.com%2Fthumbnails%2Fg9_2048x2048.webp?alt=media',
          type: 'image'
        },
        
      ]
    }
  },
  methods:{

    async openDetails(data) {
        const modal = await modalController.create({
          component: Details,
          initialBreakpoint: 0.95,
          mode: "ios",
          handle: false,
          cssClass: "details-modal",
          componentProps: { 
            data,
          }
        });

        modal.present();
      },

    openContact(){
        menuController.open('contact-menu');
    },

    scrollToSection(section){
      const scrollContent = document.querySelector('ion-content.main-content');
      let y = document.getElementById(section).offsetTop;
      scrollContent.scrollToPoint(0, y - 64, 500)
    },
  },
  setup() {
    return { modules: [Pagination, Autoplay, Navigation, EffectCreative]};
  }
});
</script>

<style scoped>
 /* SLIDER */
  #slider .swiper-imagenes{
    padding: 0;
    height: 100%;
    min-height: 100%;
    overflow: hidden;
  }

  .swiper-imagenes .swiper-info{
    width: 100%;
    padding: 24px;
    color: var(--ion-color-light);
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50%;
    transform: translateX(-50%) translateY(-50%);
  }


  .swiper-imagenes .swiper-info .subtitle{
    padding: 24px 0;
    color: var(--ion-color-light);
    font-weight: 400 !important;
  }


  .swiper-imagenes .swiper-imagen{
      display: block;
      width: 100%;
      height: auto;
      position: relative;
      overflow: hidden;
      cursor: pointer;
      margin: 0;
      background: #000;
      aspect-ratio: 16 / 9;
      border-radius: 24px;
  }

  .swiper-imagenes .swiper-imagen img{
      display: block;
      width: 100%;
      max-width: 100%;
      height: 100%;
      max-height: 100%;
      object-fit: cover;
      object-position: center;
      opacity: 0.5;
  }

  /* SERVICES */
  .services{
    margin-left: -16px;
    margin-right: -16px;
  }

  .service{
    margin: 16px;
    width: calc(100% - 32px);
    cursor: pointer;
    border-radius: 16px;
  }

  .service .image{
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    object-position: center;
    aspect-ratio: 1 / 1;
    border-radius: 16px;
  }


  .service .text .title{
    padding: 12px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
           line-clamp: 2; 
    -webkit-box-orient: vertical;
    color: var(--ion-color-dark);
  }

  .service .text .description{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
            line-clamp: 3; 
    -webkit-box-orient: vertical;
  }

  /* ABOUT */
  .about-image{
    width: 100%;
    height: auto;
    object-fit: contain;
    object-position: center;
    border-radius: 0;
    margin-top: 48px;
  }

  .about-text{
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
  }

  .about-paragraph{
    color: var(--ion-color-dark) !important;
  }

  .about-paragraph:deep(p){
    padding-bottom: 16px;
  }

  .caracteristicas-container{
    margin: -16px;
  }

  /* BRANDS */
    .brands{
      display: contents;
    }

    .brands ion-row{
      margin-top: 32px;
    }

    .brand{
      width: 100%;
      padding: 24px;
      object-fit: contain;
      object-position: center;
    }

  
  /* FEATURES */
  .feature{
    margin: 16px;
    width: calc(100% - 32px);
    padding: 24px;
  }

    .feature .icon{
      width: 64px;
      height: 64px;
      margin-bottom: 24px;
      color: var(--ion-color-primary);
    }

    .feature .title{
      padding-bottom: 16px;
    }

    /* GALLERY */
    .mansory-gallery{
      max-width: 100%;
      margin: -16px;
      columns: 3 280px;
      column-gap: 16px;
    }

    .mansory-gallery .mansory-img {
        margin: 16px;
        display: inline-block; /* stops block being split across columns. Could use break-inside: avoid for modern browsers; */
        width: calc(100% - 16px);
        break-inside: avoid;
        text-align: center;
        font-weight: bold;
    }

    .mansory-gallery .mansory-img img {
        display: block;
        width: 100%;
        max-width: 100%;
        height: 100%;
        max-height: 100%;
        object-fit: cover;
        object-position: center;
        border-radius: 24px;
    }


  /* TESTIMONIALS */
  #testimonios{
    position: relative;
  }

  .swiper-testimonials{
    border-radius: 24px;
    background: linear-gradient(45deg, rgba(129,166,119, 1) 0%, rgba(198,215,193, 1) 100%);
    width: 100%;
    padding: 136px;
  }

  .testimonial{
    max-width: 960px;
    margin: 0 auto;
    padding: 80px;
    text-align: left;
    background: var(--ion-color-light-tint);
    border-radius: 24px;
  }

  .testimonial .avatar{
    width: 80px;
    height: 80px;
    border-radius: 100%;
    margin-bottom: 24px;
    display: block;
  }

    .testimonial .avatar img {
      display: block;
      width: 100%;
      max-width: 100%;
      height: 100%;
      max-height: 100%;
      object-fit: cover;
      object-position: center;
      border-radius: 100%;
    }

  .testimonial .comment{
    padding-bottom: 32px;
  }

  .testimonial .stars{
    margin-top: 12px;
    margin-bottom: 24px;
  }

  .testimonial .provider{
      display: block;
      width: 100%;
      max-width: 100%;
      height: 100%;
      max-height: 48px;
      object-fit: contain;
      object-position: left top;
  }

  .more-testimonials{
    position: absolute;
    bottom: 44px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 999;
  }
  
  /* FAQS */

  /* NEWS */
  .new{
    width: 100%;
    text-align: left;
  }

  .new .title{
    margin-bottom: 16px !important;
    overflow: hidden;
    text-overflow: clip;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
          line-clamp: 2; 
    -webkit-box-orient: vertical;
  }
  .new .metadata{
    margin-bottom: 16px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
          line-clamp: 1; 
    -webkit-box-orient: vertical;
  }
  .new .description{
    margin-bottom: 24px !important;
        overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
          line-clamp: 2; 
    -webkit-box-orient: vertical;
  }

  .new .action{
    min-height: 40px;
  }

  .whatsapp{
    --background: #25d366 !important;
    margin: 1rem;
  }

  .swiper-imagenes .swiper-imagen{
      aspect-ratio: 16 / 9;
  }

  .swiper-imagenes .swiper-imagen video{
      width: 100%;
      height: 100%;
  }

  /* RESPONSIVE */
  @media (max-width: 540px) {
    .swiper-imagenes .swiper-info{
      width: 95%;
    }

    .swiper-imagenes .swiper-info .title{
      font-size: 48px !important;
    }

    /* SERVICES */
      .order{
        margin-top: 16px;
      }

      .services{
        margin-left: -8px;
        margin-right: -8px;
      }

      .service{
        margin: 8px;
        margin-bottom: 16px;
        width: calc(100% - 16px);
      }


    /* GALLERY */
      .mansory-gallery{
        margin: -8px;
        column-gap: 8px;
      }

      .mansory-gallery .mansory-img {
          margin: 8px;
          width: 100%;
      }

    /* TESTIMONIALS */
    .swiper-testimonials{
      padding: 32px;
    }

    .testimonial{
      padding: 32px;
    }

    .more-testimonials{
      position: relative;
      bottom: -32px;
      left: 50%;
      transform: translateX(-50%);
      --ion-color-base: var(--ion-color-dark) !important;
      z-index: 999;
    }

    .more-testimonials ion-icon{
      color: var(--ion-color-dark) !important;
    }

  }
  @media (min-width: 540px) and (max-width: 720px) {
    .swiper-imagenes .swiper-info{
      width: 95%;
    }

    .swiper-imagenes .swiper-info .title{
      font-size: 48px !important;
    }

    .order{
      margin-top: 16px;
    }

        /* SERVICES */
      .order{
        margin-top: 16px;
      }

      .services{
        margin-left: -8px;
        margin-right: -8px;
      }

      .service{
        margin: 8px;
        margin-bottom: 16px;
        width: calc(100% - 16px);
      }


    /* GALLERY */
      .mansory-gallery{
        margin: -8px;
        column-gap: 8px;
      }

      .mansory-gallery .mansory-img {
          margin: 8px;
          width: 100%;
      }

    /* TESTIMONIALS */
    .swiper-testimonials{
      padding: 32px;
    }

    .testimonial{
      padding: 32px;
    }

    .more-testimonials{
      position: relative;
      bottom: -32px;
      left: 50%;
      transform: translateX(-50%);
      --ion-color-base: var(--ion-color-dark) !important;
      z-index: 999;
    }

    .more-testimonials ion-icon{
      color: var(--ion-color-dark) !important;
    }
  }

  @media (min-width: 720px) and (max-width: 960px) {
    .swiper-imagenes .swiper-info{
      width: 50%;
    }
  }

  @media (min-width: 960px) and (max-width: 1140px) {
    .swiper-imagenes .swiper-info{
      width: 50%;
    }
  }

  @media (min-width: 1140px) and (max-width: 1280px) {

    .swiper-imagenes .swiper-info{
      width: 50%;
    }
  }

  @media (min-width: 1280px) {

    .swiper-imagenes .swiper-info{
      width: 50%;
    }

  }


</style>
