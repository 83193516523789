<template>
    <ion-header class="ion-no-border" id="header">
        <div color="transparent" class="header-item flex">

            <a href="/" class="logo" slot="start">
                <img src="assets/logo.png" alt="Los Reyes de La Guia de Chiclana"/>
            </a>

            <ion-buttons class="navigation header-nav hide-xs hide-sm hide-md">
                <ion-button @click="scrollToSection('sobre')">
                    <ion-label>Sobre</ion-label>
                </ion-button>
                <ion-button @click="scrollToSection('caracteristicas')">
                    <ion-label>Regalos</ion-label>
                </ion-button>
                <ion-button @click="scrollToSection('galeria')">
                    <ion-label>Galería</ion-label>
                </ion-button>
                <ion-button @click="scrollToSection('preguntas')">
                    <ion-label>Información</ion-label>
                </ion-button>
            </ion-buttons>

            <ion-buttons slot="end" class="hide-xs hide-sm hide-md social-icons">
                <ion-button href="https://www.facebook.com/chiclanaguiadempresas/" target="_blank">
                    <ion-icon slot="icon-only" src="/assets/img/uicons/fi-brands-facebook.svg"></ion-icon>
                </ion-button>
                <ion-button href="https://www.instagram.com/laguiadechiclana/" target="_blank">
                    <ion-icon slot="icon-only" src="/assets/img/uicons/fi-brands-instagram.svg"></ion-icon>
                </ion-button>
            </ion-buttons>

            <ion-menu-button  style="margin-left: auto;" class="hide-lg hide-xl"></ion-menu-button>

        </div>
    </ion-header>
</template>

<script>
import { defineComponent } from 'vue';
import { IonHeader, IonButtons, IonButton, IonIcon, IonLabel, IonMenuButton, menuController } from '@ionic/vue';
import { mapState } from 'vuex';

export default defineComponent({
  name: 'HeaderComponent',
  props: ['activePage'],
  components: { IonHeader, IonButtons, IonButton, IonIcon, IonLabel, IonMenuButton },
  data() {
    return {
    }
  },
  computed: {
    ...mapState(['miniweb'])
  },
  methods: {
    openContact(){
        menuController.open('contact-menu');
    },

    scrollToSection(section){
      const scrollContent = document.querySelector('ion-content.main-content');
      let y = document.getElementById(section).offsetTop;
      scrollContent.scrollToPoint(0, y - 64, 500)
    },
  },
});
</script>

<style scoped>
    #header{
        overflow: visible;
        background: transparent;
        border-bottom: 0;
        margin: 24px 0;
    }

    .header-item{
        --inner-padding-start: 0;
        --inner-padding-end: 0;
        --inner-padding-top: 0;
        --inner-padding-bottom: 0;
        --padding-start: 0;
        --padding-end: 0;
        display: flex;
        align-items: center;
        height: 40px;
        overflow: visible;
        margin: 0 auto;
    }

    .logo{
        display: block;
        cursor: pointer;
        width: auto;
        min-width: fit-content;
        height: 48px;
        margin: 0;
    }

    .logo img{
        height: 100%;
    }

    .header-button{
        margin-left: 24px; 
        min-height: 40px;
    }

    .header-nav{
      position: relative;
      width: 100%;
      padding: 0 24px;
      justify-content: center;
    }



@media (max-width: 540px) {

}

@media (min-width: 540px) and (max-width: 720px) {

}

@media (min-width: 720px) and (max-width: 960px) {

}

@media (min-width: 960px) and (max-width: 1140px) {

}

@media (min-width: 1140px) and (max-width: 1280px) {

}

@media (min-width: 1280px) {

}
    
</style>