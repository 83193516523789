/* eslint-disable no-unused-vars */
import { createStore } from 'vuex'
import { db } from '@/firebaseConfig';
import { toastController } from '@ionic/vue';
import { closeOutline } from 'ionicons/icons';


export const store = createStore({
    state: {
      miniweb: undefined,
    },
    mutations: {
      // Miniweb
      setMiniweb(state, payload){
        state.miniweb = payload;
      },
    },
    actions: {

        // Miniweb
        async getMiniweb({commit}){
          if(this.state.miniweb === undefined){
              let data;
              await db.collection('/miniwebs').doc('losreyesdelaguiadechiclana.com').get().then(res => {
                  data = res.data();
              })
              .catch(err => {
                  console.log(err);
              });
              commit('setMiniweb', data);
              return data;
          }
        },

        async openToast({commit}, {message, color}) {
            const toast = await toastController
              .create({
                message,
                duration: 2500,
                cssClass: 'toast',
                mode: 'ios',
                color,
                position: 'bottom',
                buttons: [
                 {
                    icon: closeOutline,
                    role: 'cancel',
                  }
                ]
              })
            return await toast.present();
        },
    },
    getters: {

    },
    modules: {},
});
  