<template>
    <ion-footer class="ion-no-border" id="footer">
        <div color="transparent" class="footer-item">
            <ion-grid>
                <ion-row class="main-footer">
                    <ion-col size="12" size-lg="4">
                        <a href="/" class="logo">
                            <img src="assets/logo.png" alt="Los Reyes de La Guía de Chiclana"/>
                        </a>
                    </ion-col>
                </ion-row>

                <ion-row class="subfooter">
                    <ion-col size="12" size-lg="4">
                        <p class="copyright">&copy; {{year}} losreyesdelaguiadechiclana.com</p>
                    </ion-col>
                    <ion-col size="12" size-lg="8" style="display: flex">
                        <ion-chip class="powered" button style="filter: invert(1); --background: var(--ion-color-primary)">
                            <ion-label color="dark">Powered by Evolbe Miniweb</ion-label>
                        </ion-chip>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </div>
    </ion-footer>
</template>

<script>
import { defineComponent } from 'vue';
import { IonFooter, IonButtons, IonButton, IonChip, IonLabel } from '@ionic/vue';
import { mapState } from 'vuex';

export default defineComponent({
  name: 'HeaderComponent',
  props: ['activePage'],
  components: { IonFooter, IonChip, IonLabel},
  data() {
    return {
        year: new Date().getFullYear()
    }
  },
  computed: {
    ...mapState(['miniweb'])
  },
  methods: {

  },
});
</script>

<style scoped>
    #footer{
        overflow: hidden;
        background: transparent;
        border-bottom: 0;
        margin: 24px 0;
    }

    .footer-item{
        --inner-padding-start: 0;
        --inner-padding-end: 0;
        --inner-padding-top: 0;
        --inner-padding-bottom: 0;
        --padding-start: 0;
        --padding-end: 0;
        overflow: visible;
        margin: 0 auto;
    }

    .footer-nav{
      position: relative;
      width: 100%;
      padding-left: 24px;
      justify-content: end;
    }

    .main-footer{
        padding-bottom: 24px;
        border-bottom: 1px solid var(--ion-color-light-shade);
    }

    .subfooter{
        padding-top: 24px;
    }

    .logo{
        display: block;
        cursor: pointer;
        width: auto;
        min-width: fit-content;
        height: 48px;
        margin: 0;
    }

    .logo img{
        height: 100%;
    }
    

    .powered{
        margin-left: auto;
        height: 40px;
    }

    .copyright{
        line-height: 40px;
    }

        /* RESPONSIVE */
    @media (max-width: 540px) {
        .footer-nav{
            margin-top: 16px;
            padding-left: 0;
            justify-content: start;
        }

        .powered{
            margin-left: 0;
            margin-top:16px;
        }

        .copyright{
            line-height: initial;
        }
    }

    @media (min-width: 540px) and (max-width: 720px) {

    }

    @media (min-width: 720px) and (max-width: 960px) {
    }

    @media (min-width: 960px) and (max-width: 1140px) {
        .footer-nav{
            margin-top: 16px;
        }
    }

    @media (min-width: 1140px) and (max-width: 1280px) {
        .footer-nav{
            margin-top: 16px;
        }
    }

    @media (min-width: 1280px) {
        .footer-nav{
            margin-top: 16px;
        }
    }
    
</style>